import React from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Button } from "gatsby-material-ui-components";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1.1em",
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
}));

const ArrowButton = ({
  color,
  label,
  to,
  href,
  target,
  noPaddingLeft,
  noPaddingRight,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Button
      to={to}
      href={href}
      className={clsx(classes.root, {
        [classes.noPaddingLeft]: noPaddingLeft,
        [classes.noPaddingRight]: noPaddingRight,
      })}
      variant="text"
      color={color}
      target={target}
    >
      <Grid container direction="row" spacing={matches ? 2 : 0} alignItems="flex-start">
        <Grid item>{label}</Grid>
        <Grid item>
          <ChevronRightIcon />
        </Grid>
      </Grid>
    </Button>
  );
};

ArrowButton.defaultProps = {
  className: null,
  target: "__self",
  noPaddingLeft: true,
  noPaddingRight: false,
};

ArrowButton.propTypes = {
  color: PropTypes.string.isRequired,
  noPaddingLeft: PropTypes.bool.isRequired,
  noPaddingRight: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

export default ArrowButton;
