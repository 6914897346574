import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ArrowButton from "../../components/common/ArrowButton";

const useStyles = makeStyles((theme) => ({
  imgRounded: {
    borderRadius: "12px 12px 0 0",
  },
  title: {
    fontWeight: 600,
    minHeight: 150,
    fontSize: "1.2em",
  },
  content: {
    marginTop: theme.spacing(4),
    minHeight: 120,
  },
  highlight: {
    border: "solid 1px #E7EBF4",
    borderRadius: 12,
    padding: 0,
  },
  enLight: {
    color: theme.palette.primary.light,
  },
  date: {
    color: theme.palette.primary.dark,
  },
  top: {
    marginTop: 0,
  },
}));

const Highlight = ({ highlight, langKey, logoHeight, btnLabel }) => {
  const theme = useTheme();
  const classes = useStyles();
  if (!highlight) return null;
  return (
    <Grid item xs={4}>
      <div className={classes.highlight}>
        <img
          src={highlight.image}
          alt="example1"
          width="100%"
          height="auto"
          className={classes.imgRounded}
        />
        <div style={{ padding: theme.spacing(2) }}>
          <Typography variant="body2" className={classes.top}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              component="span"
            >
              <Grid item xs={7} component="span">
                <span className={classes.enLight}>{highlight.language}</span>
                <br />
                <span className={classes.date}>{highlight.date}</span>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "right" }} component="span">
                <img src={highlight.source} alt="source" height={53} />
              </Grid>
            </Grid>
          </Typography>
          <Typography variant="h6" className={classes.title}>
            {highlight.title}
          </Typography>
          <ArrowButton
            href={highlight.url}
            color="primary"
            label={btnLabel}
            target="__blank"
          />
        </div>
      </div>
    </Grid>
  );
};

Highlight.propTypes = {
  highlight: PropTypes.object.isRequired,
  content: PropTypes.string,
  langKey: PropTypes.string,
};

export default Highlight;
